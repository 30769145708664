import _acorn from "acorn";
import _isarray from "isarray";
import _objectKeys from "object-keys";
import _foreach from "foreach";
import _util from "util";
var exports = {};
var acorn = _acorn;
var isArray = _isarray;
var objectKeys = _objectKeys;
var forEach = _foreach;
var util = _util;

exports = function (src, opts, fn) {
  if (typeof opts === "function") {
    fn = opts;
    opts = {};
  }

  if (src && typeof src === "object" && src.constructor.name === "Buffer") {
    src = src.toString();
  } else if (src && typeof src === "object") {
    opts = src;
    src = opts.source;
    delete opts.source;
  }

  src = src === undefined ? opts.source : src;
  if (typeof src !== "string") src = String(src);
  var parser = opts.parser || acorn;
  var ast = parser.parse(src, opts);
  var result = {
    chunks: src.split(""),
    toString: function () {
      return result.chunks.join("");
    },
    inspect: function () {
      return result.toString();
    }
  };

  if (util.inspect.custom) {
    result[util.inspect.custom] = result.toString;
  }

  var index = 0;

  (function walk(node, parent) {
    insertHelpers(node, parent, result.chunks);
    forEach(objectKeys(node), function (key) {
      if (key === "parent") return;
      var child = node[key];

      if (isArray(child)) {
        forEach(child, function (c) {
          if (c && typeof c.type === "string") {
            walk(c, node);
          }
        });
      } else if (child && typeof child.type === "string") {
        walk(child, node);
      }
    });
    fn(node);
  })(ast, undefined);

  return result;
};

function insertHelpers(node, parent, chunks) {
  node.parent = parent;

  node.source = function () {
    return chunks.slice(node.start, node.end).join("");
  };

  if (node.update && typeof node.update === "object") {
    var prev = node.update;
    forEach(objectKeys(prev), function (key) {
      update[key] = prev[key];
    });
    node.update = update;
  } else {
    node.update = update;
  }

  function update(s) {
    chunks[node.start] = s;

    for (var i = node.start + 1; i < node.end; i++) {
      chunks[i] = "";
    }
  }
}

export default exports;